export default function SbImageHandler(src, option) {
  if (!src) return null;

  const imageService = '//img2.storyblok.com/';
  let path;
  if (src.includes('https://')) {
    path = src.replace('https://a.storyblok.com', '');
  } else {
    path = src.replace('//a.storyblok.com', '');
  }
  let size;

  if (!option) {
    size = `1920x1080`;
  } else {
    size = option;
  }

  return imageService + size + path;
}
