import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import BlogContent from './blog-content';
import MainSliderTwo from '../slider/main-slider-2';
import Layout from '../layout';

const BlogDetails = ({ name, content, published }) => {
  if (!content) return null;
  const { author, image, intro, long_text } = content;

  console.log(published);

  return (
    <Layout>
      <MainSliderTwo
        content={{ title: name, bg_colour: { color: '#F9DEDC' } }}
      />

      <section className="blog-details pt-120 pb-40">
        <Container>
          <Row>
            <Col md={12} lg={8} style={{ margin: '0 auto' }}>
              <BlogContent
                published={published}
                content={{
                  author,
                  image,
                  intro,
                  long_text,
                  name,
                }}
              />
            </Col>
          </Row>
        </Container>
      </section>
    </Layout>
  );
};

export default BlogDetails;
