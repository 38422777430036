import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

const MainSliderTwo = ({ content }) => (
  <section className="main-slider main-slider__two">
    <div style={{ backgroundColor: content.bg_colour.color || '#c4c4c4' }}>
      <Container>
        <Row className=" justify-content-start">
          <Col xl={8} lg={12} className="text-left">
            {content.title && <h2>{content.title}</h2>}
            {content.copy && <p>{content.copy}</p>}
          </Col>
        </Row>
      </Container>
    </div>
  </section>
);

export default MainSliderTwo;
