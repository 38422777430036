/* eslint-disable react/no-danger */
import React from 'react';
import sanitizeHtml from 'sanitize-html';
import moment from 'moment';
import SbImageHandler from '../../helpers/SbImageHandler';
import SbRichtext from '../../helpers/SbRichtext';

const BlogContent = ({ content, published }) => {
  const dirty = SbRichtext(content.long_text);
  const clean = sanitizeHtml(dirty, {
    allowedTags: [
      'h2',
      'h3',
      'h4',
      'p',
      'b',
      'u',
      'i',
      'strike',
      'blockquote',
      'a',
      'hr',
      'ul',
      'ol',
      'li',
    ],
    allowedAttributes: {
      a: ['href', 'target'],
    },
  });

  return (
    <div>
      <div className="blog-card__image">
        <img src={SbImageHandler(content.image, '770x452')} alt="" />
        <div className="blog-card__date">
          {moment(published).format('D MMM')}
        </div>
      </div>
      <div className="blog-card__meta d-flex justify-content-start mt-0 mb-0">
        <a href="news-details.html">
          <i className="far fa-user-circle" /> {content.author}
        </a>
      </div>
      <h2>{content.name}</h2>
      <span dangerouslySetInnerHTML={{ __html: clean }} />
    </div>
  );
};

export default BlogContent;
